@keyframes showMessage {
    from {
        transform: translateX(50%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes hideMessage {
    from {
        transform: translateY(0%);
        opacity: 1;
    }

    to {
        transform: translateY(-20%);
        opacity: 0;
    }
}

.message-show {

    /* アニメーション名を指定する */
    animation-name: showMessage;
    /* アニメーションの実行時間を指定する */
    animation-duration: .5s;
    /* アニメーションのタイミング関数を指定する */
    animation-timing-function: ease-in-out;
    /* アニメーションの遅延時間を指定する */
    /* animation-delay: 1s; */
    /* アニメーションの繰り返し回数を指定する */
    /* animation-iteration-count: infinite; */
    /* アニメーションの方向を指定する */
    animation-direction: alternate;
    /* アニメーション終了時のスタイルを指定する */
    animation-fill-mode: forwards;
    /* アニメーションの再生・一時停止を指定する */
    /* animation-play-state: running; */
}

.message-hide {
    /* アニメーション名を指定する */
    animation-name: hideMessage;
    /* アニメーションの実行時間を指定する */
    animation-duration: .5s;
    /* アニメーションのタイミング関数を指定する */
    animation-timing-function: ease-in-out;
    /* アニメーションの遅延時間を指定する */
    /* animation-delay: 4.5s; */
    /* アニメーションの繰り返し回数を指定する */
    /* animation-iteration-count: infinite; */
    /* アニメーションの方向を指定する */
    animation-direction: alternate;
    /* アニメーション終了時のスタイルを指定する */
    animation-fill-mode: forwards;
    /* アニメーションの再生・一時停止を指定する */
    /* animation-play-state: running; */
}

.message-list {
    max-width: 24rem;
    position: fixed;
    word-break: break-all;
    z-index: 999999;
    right: 1rem;
    top: 1rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}